/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/alt-text */
import { useSnackbar } from 'notistack';
import React, { FC, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

import Skeleton from '@material-ui/lab/Skeleton';
import { SpreadsheetComponent } from '@syncfusion/ej2-react-spreadsheet';
import { Editor } from '@tinymce/tinymce-react';

interface Props {
  content: string;
  attachmentApi: (
    params: { [paramName: string]: string | number | boolean },
    data: FormData,
    query: { [paramName: string]: string | number | boolean | object }
  ) => Promise<FormData>;
  ref: any;
  disabled?: boolean;
  height?: number | string;
  pdfLink?: string;
  viewMode?: boolean;
  loading?: boolean;
  isExcel?: boolean;
}

const UPLOAD_IMAGE_FAIL = 'Problema incarcare poza';

const TextEditor: FC<Props> = forwardRef(
  ({ content, attachmentApi, disabled, height, viewMode, pdfLink, loading, isExcel }, ref) => {
    const { enqueueSnackbar } = useSnackbar();
    const editorRef = useRef(null);
    const spreadsheetRef = React.useRef(null);
    const spreadsheetCreated = useRef(false);
    const [pdfLoading, setPdfLoading] = useState<boolean>(true);

    useEffect(() => {
      if (loading) setPdfLoading(true);
    }, [loading]);

    useImperativeHandle(ref, () => ({
      getContent: (params?: any) => {
        return editorRef.current?.getContent(params);
      },
      getExcelContent: async () => {
        const spreadsheet = spreadsheetRef.current;
        const data = await spreadsheet.saveAsJson();
        console.log('Excel data -->', data);
        const { jsonObject } = data;
        return JSON.stringify(jsonObject);
      }
    }));

    const changeExcelData = useCallback(() => {
      if (spreadsheetRef.current && spreadsheetCreated.current) {
        if (content === '{}') {
          spreadsheetRef.current.refresh(true);
        } else {
          const jsonData = JSON.parse(content);
          spreadsheetRef.current.openFromJson({ file: jsonData });
        }
      }
    }, [content]);

    useEffect(() => {
      if (isExcel) {
        setTimeout(() => changeExcelData(), 100);
      }
    }, [content, isExcel, changeExcelData]);

    const created = () => {
      spreadsheetCreated.current = true;
      if (content && content !== '{}') {
        try {
          changeExcelData();
        } catch (e) {
          console.error('Could not load Excel Json', e);
        }
      }
    };

    const handleUploadClick = () => {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.click();
    };

    const uploadImageCallBack = async (blobInfo: any, success: any, failure: any) => {
      const data = new FormData();
      data.append('attachment', blobInfo.blob(), blobInfo.filename());
      try {
        const response: any = await attachmentApi(null, data, { isPublic: true });
        success(response.uri);
      } catch (error) {
        enqueueSnackbar(`${UPLOAD_IMAGE_FAIL}: ${error.message}`, {
          variant: 'error'
        });
        failure(error.message);
      }
    };

    const renderLoading = () => (
      <div style={{ height: '100%' }}>
        {[...Array(30)].map((i, k) => (
          <Skeleton
            key={i}
            style={{ marginLeft: k % 5 === 0 ? 70 : 0, marginRight: k % 3 === 0 ? 70 : 0 }}
            variant="text"
            height={30}
          />
        ))}
      </div>
    );

    return loading ? (
      renderLoading()
    ) : viewMode ? (
      <div style={{ height: '100%' }}>
        <object
          data={pdfLink}
          type="application/pdf"
          width="100%"
          onLoad={() => setPdfLoading(false)}
          height={pdfLoading ? 0 : height || 800}
        />
        {pdfLoading && renderLoading()}
      </div>
    ) : isExcel ? (
      <SpreadsheetComponent
        ref={spreadsheetRef}
        created={created}
        allowSave
        saveUrl="http://localhost:6002/api/spreadsheet/save"
        allowOpen
        openUrl="https://services.syncfusion.com/react/production/api/spreadsheet/open"
        height={height || 700}
      />
    ) : (
      <Editor
        tinymceScriptSrc="/tinymce/tinymce.min.js"
        onInit={(evt, editor) => {
          editorRef.current = editor;
        }}
        apiKey="gpl"
        initialValue={content}
        disabled={disabled}
        init={{
          height: height || 700,
          toolbar_mode: 'floating',
          content_css: 'document',
          plugins:
            'advlist anchor autolink charmap code codesample directionality fullpage help hr image imagetools insertdatetime link lists media nonbreaking pagebreak preview print searchreplace table template textpattern toc visualblocks visualchars wordcount',
          toolbar:
            'undo redo | formatselect | bold italic strikethrough forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat',
          file_picker_types: 'image',
          file_picker_callback: handleUploadClick,
          images_upload_handler: uploadImageCallBack,
          pagebreak_separator: '<div style="page-break-before: always !important;">&nbsp;</div>'
        }}
      />
    );
  }
);

export default TextEditor;
