import React, { ChangeEvent, FC } from 'react';
import { Eye, EyeOff } from 'react-feather';
import { DocumentAttributes, TemplateAttribute } from 'src/types/contract';
// import { DATE_FORMAT } from 'src/utils/formatDate';
import { formatLabelText } from 'src/utils/template';

import { Divider, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';

import CustomSelect from './CustomSelect';

interface Props {
  tempAttributes: TemplateAttribute[];
  data: DocumentAttributes;
  onChange: (data: DocumentAttributes) => void;
  dataKey?: string;
  title?: string;
  index?: number | string;
  error?: { [key: string]: boolean };
  requiredFields?: TemplateAttribute[];
  disabledFields?: TemplateAttribute[];
  showDetails?: () => void;
  showDetailsState?: boolean;
}

const DocumentForm: FC<Props> = ({
  data,
  tempAttributes,
  onChange,
  title,
  index,
  dataKey = 'data.',
  error,
  requiredFields,
  showDetails,
  showDetailsState,
  disabledFields
}) => {
  const preOnChange = (newData: DocumentAttributes) => {
    const autoChanges = checkAutoFields(newData);
    onChange({ ...newData, ...autoChanges });
  };

  const changeHandler = (field: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const newData = { ...data, [field]: event.target.value };
    preOnChange(newData);
  };

  const dateChangeHandler = (field: string) => (date: moment.Moment) => {
    const val = date?.format('YYYY-MM-DD');
    const newData = { ...data, [field]: val };
    preOnChange(newData);
  };

  const checkAutoFields = (newData: DocumentAttributes) => {
    const changeData: DocumentAttributes = {};
    tempAttributes?.forEach((item) => {
      if (!item.type) return;
      let { key } = item;
      if (index) {
        const nk = key.replace(dataKey, '');
        key = `${dataKey}${index}.${nk}`;
      }
      const [type, func, values] = item.type.split(':');
      if (type !== 'auto') return;
      const itemSplit = key.split('.');
      const field = itemSplit.pop();
      const i = itemSplit.join('.');
      if (func === 'daysAdd') {
        // Fixme: If you change a date. object will not update auto:daysAdd
        const [dateField, noOfDaysField] = values.split(',');
        let keyDateFiled = `${i}.${dateField}`;
        if (dateField.indexOf('root.') === 0) {
          keyDateFiled = dateField.substring(5);
        }
        if (dateField.indexOf('data.') === 0) {
          keyDateFiled = dateField;
        }
        const dateValue = newData[keyDateFiled];
        const noOfDaysValue = newData[`${i}.${noOfDaysField}`];
        if (!dateValue || !noOfDaysValue) return;
        const dateValueF = new Date(dateValue.toString());
        const noOfDaysValueF = parseInt(noOfDaysValue.toString(), 10);
        const valueF = new Date(dateValueF.setDate(dateValueF.getDate() + noOfDaysValueF)).toISOString().split('T')[0];
        changeData[`${i}.${field}`] = valueF;
      }
    });
    return changeData;
  };

  const renderAttributeField = (item: TemplateAttribute) => {
    // const keys = item.key.split(/[.[\]]/);
    let label = formatLabelText(item.key, dataKey);
    const required = requiredFields?.findIndex((el: TemplateAttribute) => el.key === item.key) > -1;
    if (required) label += '*';
    let { type, max } = item;
    let options = [];
    let { key } = item;
    if (index) {
      const nk = key.replace(dataKey, '');
      key = `${dataKey}${index}.${nk}`;
    }
    const value = data[key];
    if (item.type && item.type.indexOf('list') >= 0) {
      options = item.type.split(':')[1].split(',');
      type = 'list';
    }
    if (item.type && item.type.indexOf('auto:daysAdd') >= 0) {
      // checkAutoFields(item, key);
      type = 'auto-date';
    }

    const isDisabled = Boolean(disabledFields?.some((df) => df.key === key));
    switch (type) {
      case 'number':
        return (
          <TextField
            label={label}
            name={key}
            fullWidth
            onChange={changeHandler(key)}
            value={value || null}
            variant="outlined"
            type="number"
            InputLabelProps={{ shrink: true }}
            InputProps={{ inputProps: { min: 0 } }}
            error={error?.[key]}
            helperText={error?.[key] && 'Acest camp este obligatoriu!'}
            disabled={isDisabled}
          />
        );
      case 'date':
        return (
          <KeyboardDatePicker
            label={label}
            name={key}
            inputVariant="outlined"
            fullWidth
            value={value ? new Date(`${value}`) : null}
            format="DD.MM.YYYY"
            onChange={dateChangeHandler(key)}
            error={error?.[key]}
            helperText={error?.[key] && 'Acest camp este obligatoriu!'}
            maxDate={max || undefined}
            disabled={isDisabled}
          />
        );
      case 'list':
        return (
          <CustomSelect
            label={label}
            onChange={changeHandler(key)}
            value={value ?? null}
            list={options}
            disabled={isDisabled}
          />
        );
      case 'auto-date':
        return (
          <KeyboardDatePicker
            label={label}
            name={key}
            inputVariant="outlined"
            fullWidth
            value={value ? new Date(`${value}`) : null}
            format="DD.MM.YYYY"
            onChange={dateChangeHandler(key)}
            disabled
            error={error?.[key]}
            helperText={error?.[key] && 'Acest camp este obligatoriu!'}
            maxDate={max || undefined}
          />
        );
      default:
        return (
          <TextField
            label={label}
            name={key}
            fullWidth
            onChange={changeHandler(key)}
            value={value ?? ''}
            variant="outlined"
            error={error?.[key]}
            helperText={error?.[key] && 'Acest camp este obligatoriu!'}
            disabled={isDisabled}
          />
        );
    }
  };

  let parsedAttributes =
    tempAttributes?.filter(
      (a) =>
        a.key.indexOf(dataKey) >= 0 && (a.type ? a.type.indexOf('math') < 0 : true) && a.key.indexOf('probeTable') < 0
    ) || [];
  if (requiredFields?.length) {
    parsedAttributes = [...requiredFields, ...parsedAttributes];
  }

  return (
    <>
      {title && (
        <Grid item xs={12}>
          <Divider style={{ marginBottom: 30 }} />
          <Typography variant="h4" color="textPrimary">
            {title}
            <IconButton color="primary" aria-label="details" onClick={showDetails}>
              {showDetailsState ? <EyeOff /> : <Eye />}
            </IconButton>
          </Typography>
        </Grid>
      )}
      {parsedAttributes?.map((item) => (
        <Grid item xs={12} md={6} key={item.key}>
          {renderAttributeField(item)}
        </Grid>
      ))}
    </>
  );
};

export default DocumentForm;
