import { useSnackbar } from 'notistack';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Edit3, FileText, Save, Send, XSquare } from 'react-feather';
import Button from 'src/components/Button';
import DialogAdvanced from 'src/components/DialogAdvanced';
import TextEditor from 'src/components/TextEditor';
import useAuth from 'src/hooks/useAuth';
import useGlobalModal, { MODALS } from 'src/hooks/useGlobalModal';
import api, { API_PDF_DOWNLOAD } from 'src/lib/api';
import { Theme } from 'src/theme';
import { Contract, CONTRACT_TYPE, ProjectContract } from 'src/types/contract';
import { Email } from 'src/types/email';
import { UploadedFile } from 'src/types/file';
import downloadContractUtil from 'src/utils/downloadContract';

import { Card, CardContent, CardHeader, Divider, Grid, IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { Refresh } from '@material-ui/icons';
import CustomDialog from './Dialog';
import Label from './Label';
import SignaturePad from './SignaturePad';

const useStyles = makeStyles((theme: Theme) => ({
  bg: {
    backgroundColor: theme.palette.background.dark
  }
}));

interface Props {
  cancel: () => void;
  setNotify: (data?: unknown) => void;
  visible: boolean;
  extra?: {
    projectContractId: string;
    canEdit: boolean;
    viewMode?: boolean;
    title?: string;
    sign?: boolean;
    canCreate?: boolean;
    refresh?: any;
    component?: React.ReactNode;
    projectContract?: ProjectContract;
  };
}

const TextEditorModal: FC<Props> = ({ extra, ...rest }) => {
  const editorRef: any = useRef();
  const classes = useStyles();
  const { openModal } = useGlobalModal();
  const { enqueueSnackbar } = useSnackbar();
  const { canEdit, viewMode, canCreate } = extra;
  const { refreshUser, updateUser, user } = useAuth();
  const { isClient } = user;
  const [signature, setSignature] = useState<UploadedFile[]>(isClient ? user.files : []);

  const [projectContract, setProjectContract] = useState<ProjectContract>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);

  const closeModal = () => {
    rest.setNotify(null);
    rest.cancel();
  };

  const fetchContract = useCallback(async () => {
    if (extra.projectContractId) {
      setLoading(true);
      try {
        const contract = await api.projectContracts.get(extra.projectContractId);
        setProjectContract(contract);
      } catch (e) {
        enqueueSnackbar(`Eroare incarcare document: ${e.message}`, { variant: 'error' });
        closeModal();
      } finally {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extra.projectContractId]);

  useEffect(() => {
    fetchContract();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extra.projectContractId]);

  const saveHandler = async () => {
    let data;
    if (projectContract?.contract?.isExcel) {
      data = await editorRef.current?.getExcelContent();
    } else {
      data = editorRef.current?.getContent();
    }

    const editContract: Contract = {
      content: data,
      project: projectContract.project,
      contractType: projectContract.contractType
    };
    try {
      await api.contracts.update(projectContract.contract.id, editContract);
      enqueueSnackbar('Document salvat cu success', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(`Eroare salvare Contract: ${e.message}`, {
        variant: 'error'
      });
    }
  };

  const regenerateHandler = async () => {
    rest.cancel();
    switch (extra.projectContract.contractType) {
      case CONTRACT_TYPE.pvp:
        openModal(MODALS.PROJECT_PVP_CREATE, {
          project: projectContract?.project,
          projectContract: extra?.projectContract,
          user
        });
        break;
      case CONTRACT_TYPE.worksheet:
        openModal(MODALS.PROJECT_WORKSHEET_CREATE, {
          projectId: projectContract?.project.id,
          projectContract: extra?.projectContract,
          user
        });
        break;
      case CONTRACT_TYPE.report:
        openModal(MODALS.PROJECT_REPORT_CREATE, {
          projectId: projectContract?.project.id,
          user,
          projectContract: extra?.projectContract
        });
        break;
      default:
        break;
    }
  };

  const onDownload = async () => {
    const url = `/api/contracts/pdf/${projectContract.contract.id}`;
    const { title } = projectContract.contract;
    const { version } = projectContract;
    setDownloadLoading(true);
    await downloadContractUtil(url, { title, version });
    setDownloadLoading(false);
  };

  const onDialogOpen = () => setVisible(true);

  const sendEmailHandler = () => {
    const email = new Email(projectContract.project, projectContract);
    openModal(MODALS.EMAIL, { email });
  };

  const revokeHandler = async () => {
    const messages = {
      success: 'Revocare raport cu succes!',
      failed: 'Problema revocare'
    };
    setLoading(true);

    try {
      const result = await api.projectContracts.revoke(projectContract?.id, { canceled: false });
      if (!result) throw new Error('Va rugam reincercati.');
      fetchContract();
      rest.setNotify(true);
      enqueueSnackbar(messages.success, { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(`${messages.failed}: ${e.message}`, {
        variant: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const signHandler = async () => {
    setLoading(true);
    try {
      const contract = await api.projectContracts.sign(projectContract?.id);
      if (!contract) throw new Error('Va rugam reincercati.');
      setProjectContract(contract);
      // fetchContract();
      // rest.setNotify(true);
      enqueueSnackbar('Document semnat cu succes.', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(`Documentul nu a putut fi semnat: ${e.message}`, {
        variant: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const clientSignHandler = async (sign: string, file: UploadedFile) => {
    setVisible(false);

    setLoading(true);
    try {
      if (!signature?.length && file && isClient) {
        setSignature([file]);
        const formData: any = new FormData();
        formData.append('attachment', file);
        const values = {
          id: user.id
        };
        Object.keys(values).forEach((key) => formData.append(key, values[key]?.toString()));

        await updateUser(formData, true);
        await refreshUser();
      }
      const contract = await api.projectContracts.clientSign(
        null,
        { uri: sign || user?.files?.[0]?.uri },
        { id: projectContract.id }
      );
      if (!contract) throw new Error('Va rugam reincercati.');
      setProjectContract(contract);
      if (isClient) {
        extra.refresh();
      } else rest?.setNotify(extra.projectContractId);

      enqueueSnackbar('Document semnat cu succes.', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(`Documentul nu a putut fi semnat cu semnatura clientului: ${e.message}`, {
        variant: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const canViewSignature = [CONTRACT_TYPE.contract, CONTRACT_TYPE.pvp, CONTRACT_TYPE.order].some(
    (el) => el === projectContract?.contractType
  );
  const hasProbes = !!projectContract?.contract?.docAttributes?.['probesData.pnos'];
  const hasPvps = !!projectContract?.contract?.docAttributes?.['pvpData.ids'];
  const hasProbeIds = !!projectContract?.contract?.docAttributes?.['probe.1.id'];
  const canRegenerate =
    (projectContract?.contractType === CONTRACT_TYPE.pvp && (hasProbes ? hasProbeIds : true)) ||
    (projectContract?.contractType === CONTRACT_TYPE.worksheet && hasProbeIds && hasPvps) ||
    (projectContract?.contractType === CONTRACT_TYPE.report && hasProbeIds);
  const contractIsSigned = projectContract?.contract.docAttributes?.signedAt;

  return (
    <DialogAdvanced {...rest} maxWidth="lg">
      <Card className={classes.bg}>
        <CardHeader
          title={
            <>
              {projectContract?.id ? `${extra.title} ${projectContract?.contract?.title}` : extra.title}{' '}
              {projectContract?.canceled && (
                <Label color="error">
                  <span style={{ fontSize: 15 }}>Anulat</span>
                </Label>
              )}
            </>
          }
          style={{ flexWrap: 'wrap' }}
          action={
            <div style={{ marginTop: 10 }}>
              {canEdit && !viewMode && projectContract?.canceled && (
                <Button
                  onClick={revokeHandler}
                  variant="contained"
                  size="small"
                  startIcon={<XSquare />}
                  loading={loading}
                >
                  <span>Revoca anularea</span>
                </Button>
              )}
              &nbsp;
              {(canEdit || canCreate) && !viewMode && projectContract?.id && (
                <Button onClick={saveHandler} variant="contained" size="small" startIcon={<Save />}>
                  Salveaza
                </Button>
              )}
              &nbsp;
              {(canEdit || canCreate) && !viewMode && projectContract?.id && canRegenerate && (
                <Button onClick={regenerateHandler} variant="contained" size="small" startIcon={<Refresh />}>
                  Regenereaza
                </Button>
              )}
              &nbsp;
              {projectContract?.id && (
                <>
                  <Button
                    onClick={contractIsSigned ? onDownload : onDialogOpen}
                    variant="contained"
                    size="small"
                    startIcon={<FileText />}
                    loading={downloadLoading}
                  >
                    PDF
                  </Button>
                  <CustomDialog
                    title="Download PDF"
                    question="Acest fisier este nu este semnat. Esti sigur ca vrei sa downloadezi acest fisier?"
                    visible={visible}
                    actionTitle="Download"
                    cancel={() => {
                      setVisible(false);
                    }}
                    content=""
                    action={onDownload}
                    custom={{
                      secondaryAction: (
                        <SignaturePad
                          type="custom"
                          customText={isClient ? 'Semneaza' : 'Semneaza client'}
                          values={signature || []}
                          onDrop={clientSignHandler}
                        />
                      )
                    }}
                  />
                </>
              )}
              &nbsp;
              {projectContract?.id && !isClient && (
                <Button onClick={sendEmailHandler} variant="contained" size="small" startIcon={<Send />}>
                  Email
                </Button>
              )}
              &nbsp;
              {projectContract?.id && extra.sign && viewMode && (
                <Button onClick={signHandler} variant="contained" size="small" startIcon={<Edit3 />}>
                  Semneaza
                </Button>
              )}
              {projectContract?.id && viewMode && canViewSignature && !contractIsSigned && (
                <SignaturePad
                  type="custom"
                  customText={isClient ? 'Semneaza' : 'Semneaza client'}
                  values={signature || []}
                  onDrop={clientSignHandler}
                />
              )}
              <IconButton aria-label="close" onClick={closeModal}>
                <CloseIcon />
              </IconButton>
            </div>
          }
        />
        <Divider />
        <CardContent style={{ height: 'calc(100vh - 150px)', padding: 10, paddingBottom: 0 }}>
          {extra?.component}
          <Grid container spacing={4} style={{ height: '100%' }}>
            <Grid item xs={12} style={{ height: '100%' }}>
              <TextEditor
                ref={editorRef}
                content={projectContract?.contract?.content}
                attachmentApi={api.contracts.attachment}
                disabled={!canEdit}
                pdfLink={`${API_PDF_DOWNLOAD}/${projectContract?.contract?.id}`}
                viewMode={viewMode}
                height="100%"
                loading={loading}
                isExcel={projectContract?.contract?.isExcel}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </DialogAdvanced>
  );
};

export default TextEditorModal;
