import React, { FC } from 'react';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

interface CustomSelectProps {
  list: any[];
  label: string;
  value: any;
  onChange: (value: any) => void;
  keyField?: string;
  valueField?: string;
  disabled?: boolean;
}

const CustomSelect: FC<CustomSelectProps> = (props) => {
  return (
    <div style={{ width: '100%' }}>
      <FormControl variant="outlined" style={{ width: '100%' }}>
        <InputLabel id="demo-simple-select-required-label">{props.label}</InputLabel>
        <Select
          labelId="demo-simple-select-required-label"
          id="demo-simple-select-required"
          value={props.value || ''}
          onChange={props.onChange}
          disabled={props.disabled || false}
        >
          <MenuItem value="">
            <em>N/A</em>
          </MenuItem>
          {props.list.map((l) => (
            <MenuItem
              key={props.valueField ? l[props.valueField] : l}
              value={props.valueField ? l[props.valueField] : l}
            >
              {props.keyField ? l[props.keyField] : l}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default CustomSelect;
