import { Category } from './category';
import { Project } from './project';

export class Product {
  category?: Category;
  name: string = '';
  um: string = '';
  price?: number;
  id?: string;
  no?: number;
  info?: string;
  created?: Date;
  updated?: Date;
}

export class LabProduct extends Product {
  id: string;
  product: Product;
  enabled: boolean;
}

export class ProjectProduct extends Product {
  enabled: boolean;
  project: Project;

  constructor(product?: Product, projectId?: string) {
    super();
    this.category = product.category;
    this.name = product.name;
    this.um = product.um;
    this.price = product.price;
    this.info = product.info;
    if (projectId) this.project = new Project(projectId);
  }
}
